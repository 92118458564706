<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="维护保养企业名称">
          <el-input v-model="companyName" placeholder="请输入维护保养企业名称" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" @click="open('add')" icon="el-icon-plus" v-if="isAdmin">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="contractList" stripe style="width: 100%" height="100%" v-loading="loading">
        <el-table-column prop="companyName" label="维护保养企业名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="personName" label="维保企业负责人" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="code" label="合同编号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="levelName" label="维护保养资质等级" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="startTime" label="合同签订日期" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.startTime|dateFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="vaildTime" label="合同起效日期" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.vaildTime|dateFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="endTime" label="合同结束日期" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.endTime|dateFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="protectLocation" label="维保部位" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="isAdmin">
              <el-button type="danger" @click="delContract(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="查看附件" placement="top">
              <el-button type="primary" @click="open('check',scope.row)" size="mini" icon="el-icon-view" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" contract="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 表单 -->
    <el-dialog :title="optFlag?'添加合同':'修改合同'" :visible.sync="contractShow" width="40%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="contract" ref="contractForm" :rules="rules" label-width="140px">
        <el-form-item label="维护保养企业名称" prop="companyName">
          <el-input v-model="contract.companyName" placeholder="请输入维护保养企业名称" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="维保企业负责人" prop="personName">
          <el-input v-model="contract.personName" placeholder="请输入维保企业负责人" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="合同编号" prop="code">
          <el-input v-model="contract.code" placeholder="请输入合同编号" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="维护保养资质等级" prop="level">
          <el-select v-model="contract.level" placeholder="请选择维护保养资质等级" clearable size="small" style="float:left">
            <el-option v-for="(item,i) in levelList" :key="i" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合同签订日期" prop="startTime">
          <el-date-picker v-model="contract.startTime" size="small" placeholder="选择合同签订日期" style="float:left">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="合同起效日期" prop="vaildTime">
          <el-date-picker v-model="contract.vaildTime" size="small" placeholder="选择合同起效日期" style="float:left">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="合同结束日期" prop="endTime">
          <el-date-picker v-model="contract.endTime" size="small" placeholder="选择合同结束日期" style="float:left">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="维护部位" prop="protectLocation">
          <el-input v-model="contract.protectLocation" placeholder="请输入维护部位" size="small" clearable :maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="上传图片">
          <el-upload class="upload-demo" ref="upload" drag action="http://jaxf.jjxsw.vip:9002/app-version/uploadFile" :limit="1" :headers="{Authorization:token}" :on-success="uploadSuccess" :on-error="uploadError">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text"><em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过2M</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span>
        <el-button @click="contractShow = false">取 消</el-button>
        <el-button type="primary" @click="addContract" v-show="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateContract" v-show="!optFlag">修 改</el-button>
      </span>
    </el-dialog>
    <!-- 附件 -->
    <el-dialog title="附件" :visible.sync="enclosureShow" width="30%" v-dialogDrag :close-on-click-modal="false">
      <el-image :src="enclosure" fit="fill" :lazy="true" :preview-src-list="[enclosure]"></el-image>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      currentPage: 1,
      total: 0,
      companyName: '',
      token: '',
      contractList: [],
      levelList: [],
      contractId: '',
      contract: {
        code: '',
        companyName: '',
        endTime: '',
        level: '',
        personName: '',
        protectLocation: '',
        startTime: '',
        vaildTime: ''
      },
      contractCopy: {},
      enclosure: '',
      contractShow: false,
      enclosureShow: false,
      optFlag: true,
      loading: false,
      rules: {
        companyName: [
          { required: true, message: '请输入维护保养企业名称', trigger: 'blur' }
        ],
        personName: [
          { required: true, message: '请输入维保企业负责人', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入合同编号', trigger: 'blur' }
        ],
        level: [
          { required: true, message: '请选择维护保养资质等级', trigger: 'change' }
        ],
        startTime: [
          { required: true, message: '请选择合同签订日期', trigger: 'change' }
        ],
        vaildTime: [
          { required: true, message: '请选择合同起效日期', trigger: 'change' }
        ],
        endTime: [
          { required: true, message: '请选择合同结束日期', trigger: 'change' }
        ],
        protectLocation: [
          { required: true, message: '请输入维护部位', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.initTable()
    this.getLevel()
    this.contractCopy = JSON.stringify(this.contract)
    this.token = sessionStorage.getItem('token')
  },
  methods: {
    handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    async initTable () {
      const data = {}
      if (this.companyName) {
        data.companyName = this.companyName
      }
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      data.pageNo = this.currentPage
      data.pageSize = 10
      this.loading = true
      const { data: result } = await this.$axios.get('/fireProtectContract/list', { params: data })
      if (result.code === 200) {
        this.contractList = result.data.result
        this.total = result.data.total
        this.loading = false
      } else {
        this.loading = false
        this.$message.error(result.msg)
      }
    },
    async addContract () {
      this.$refs.contractForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.post('/fireProtectContract/add', this.contract)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.contractShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    async updateContract () {
      this.$refs.contractForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.put(`/fireProtectContract/edit/${this.contractId}`, this.contract)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.contractShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 删除某条数据
    async delContract (id) {
      const confirm = await this.$confirm('此操作将永久删除该布局, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/fireProtectContract/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.optFlag = true
          this.contractShow = true
          this.$nextTick(() => {
            this.$refs.contractForm.clearValidate()
            this.$refs.upload.clearFiles()
          })
          break
        case 'edit':
          this.optFlag = false
          this.contract = JSON.parse(JSON.stringify(row))
          this.contractShow = true
          this.contractId = row.id
          this.$nextTick(() => {
            this.$refs.contractForm.clearValidate()
            this.$refs.upload.clearFiles()
          })
          break
        case 'check':
          if (!row.imageUrl) {
            this.$message('该条数据暂无附件')
          } else {
            this.enclosure = row.imageUrl
            this.enclosureShow = true
          }
          break
      }
    },
    async getLevel () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'protectAptitudeLevel' } })
      if (result.code === 200) {
        this.levelList = result.data
      }
    },
    uploadSuccess (res) {
      if (res.code === 200) {
        this.contract.imageUrl = res.data
        this.$message.success('上传成功')
      }
    },
    uploadError (err) {
      if (err) {
        this.$message.error('上传失败')
      }
    },
    clear () {
      this.contract = JSON.parse(this.contractCopy)
      this.$refs.contractForm.clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  box-shadow: none !important;
}
.container:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  border: none !important;
}
</style>
